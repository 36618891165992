import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"
import readMoreIcon from "../../images/circle-right-regular.svg"

import "./CaseStudies.css";


function CaseStudies(props) {
    const { data, caseStudies } = props;
    console.log(caseStudies)
    return (
        <div className="py-16 md:py-32 m-auto px-4 md:px-0 container-cs">

            <div className="w-full px-4 pb-8 block text-center" >
                <div className='ss-subtitle py-2' style={{ float: "inherit" }}>
                    <span>{data.subtitle}</span>
                </div>
                <div className='pb-2'>
                    <h2 className='w-full text-center' >
                        {data.title}
                    </h2>
                    <div className='spacer-yellow m-auto'><div><div style={{ height: "3px" }}></div></div></div>
                </div>

            </div>
            <div className='pb-12'>
                <div className='flex flex-wrap'>
                    {(caseStudies && caseStudies.length > 0) && caseStudies.map((caseStudy, i) => (
                        <a href={caseStudy.node.uri.replace("case-study", "case-studies")} key={`program-post-${i}`} className='w-full md:w-1/3 px-4 pt-6 md:pt-0'>
                            <div className='cs-wrapper' style={{ display: "grid" }}>
                                <GatsbyImage
                                    image={caseStudy.node.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                                    alt={caseStudy.node.featuredImage.node.altText}
                                    className="m-auto sc-m-img0 w-full"
                                    style={{
                                        gridArea: "1/1",
                                        // You can set a maximum height for the image, if you wish.
                                        maxHeight: 400,
                                        minHeight: "261px"
                                    }}
                                />
                                <div
                                    className='cs-overlay flex flex-wrap items-center'
                                    style={{
                                        // By using the same grid area for both, they are stacked on top of each other
                                        gridArea: "1/1",
                                        position: "relative",
                                        // This centers the other elements inside the hero component
                                        placeItems: "center",
                                        display: "grid",
                                    }}
                                >
                                    <div className='w-full anim-wr'>
                                        <h3 className='program-h3 w-full'>{caseStudy.node.title}</h3>
                                        <div className='flex justify-start'>
                                            <a href={caseStudy.node.uri.replace("case-study", "case-studies")}>ΔΕΙΤΕ ΠΕΡΙΣΣΟΤΕΡΑ</a>
                                            <a href={caseStudy.node.uri.replace("case-study", "case-studies")}>
                                                <img className='read-more-icon' src={readMoreIcon} width="17" style={{ filter: "invert(1)" }} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default CaseStudies;
