import React, { useEffect, useState, useCallback } from "react"
import { graphql, Link } from "gatsby"

import Layout from '../components/layout'

import CaseStudies from "../components/Home/CaseStudies"

function ServicesPageTemplate(props) {
    console.log(props)
    let data = props.data;

    // console.log(data)


    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://trustdev.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://trustdev.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }


    return (
        <Layout
            headerMenu={props.pageContext.headerMenu}
            footerMenu={props.pageContext.footerMenu}
            currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
            availableVersions={props.pageContext.availablePages}
            globalInfo={data.wpTemplate.globalInformation}
            breadcrumbs={data.wpPage.seo.breadcrumbs}

            locale={props.pageContext.language}
            metaData={constructMetaData(data.wpPage, props.pageContext.currentPage, props.pageContext.language)}
        // services={props.pageContext.servicesCategories}
        >
            <div>
                <section>
                    <CaseStudies
                        data={{title: "ΤΑ ΕΡΓΑ ΜΑΣ", subtitile: ""}}
                        caseStudies={data.allWpCaseStudy.edges}
                    />
                </section>
            </div>
        </Layout>
    )
}

export default ServicesPageTemplate

export const pageQuery = graphql`query GET_CASE_STUDIES_PAGE($id: String, $templateId: String, $language: WpLanguageCodeEnum) {
    wpPage(id: { eq: $id}){
        seo {
            canonical
            title
            metaDesc
            opengraphDescription
            opengraphTitle
            opengraphImage {
                sourceUrl
            }
            breadcrumbs {
                text
                url
            }
        }
        title   
    }
    allWpCaseStudy(
        filter: {language: {code: {eq: $language}}},
        sort: { fields: [date] , order: DESC },
    ){
        edges{
            node{
                title
                date
                uri
                featuredImage {
                    node{
                        altText
                        sourceUrl
                        localFile {
                            childImageSharp {
                                gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
                            }
                        }
                    }
                }
            }
        }
    }

    wpTemplate(slug: {eq: $templateId}) {
		globalInformation {
            contactInfo {
                phone {
                    label
                    text
                }
                address {
                    label
                    text
                }
                email {
                    label
                    text
                }
                workingHours {
                    label
                    text
                }
            }
            contactForm{
                title
                content
                socialLinks{
                    social{
                        type
                        link
                    }
                }
                form{
                    label
                    type
                    errorMessage
                    value
                }
                formCta
            }
            footerExtra {
                description
                copyright
            }
        }
    }
}
`
